import Login from './components/general/Login.vue';
import Dashboard from './components/dashboard/Dashboard.vue';
import Purchase_kanban from './components/dashboard/Purchase_kanban.vue'
import FBA_shipment from './components/dashboard/FBA_shipment.vue'
import Main from './components/dashboard/Main.vue'
import Review from './components/dashboard/Review.vue'
import Review_table from './components/dashboard/Review_table.vue'
import Reviewer_table from './components/dashboard/Reviewer_table'
import Auto_project from './components/dashboard/Auto_project'
import Comment_m from './components/dashboard/Comment_m'
import Monitor from './components/dashboard/Monitor'
import Auto_update from './components/dashboard/Auto_update'
import Change_l from './components/dashboard/Change_l'
import Sample from './components/dashboard/Sample'
import Parent_calculate from './components/dashboard/Parent_calculate'

export default [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        children:[{
            path:'/dashboard/purchase_kanban',
            name:'purchase_kanban',
            component:Purchase_kanban
        },
        {
            path:'/dashboard/fba_shipment',
            name:'fba_shipment',
            component:FBA_shipment
        },
        {
            path:'/dashboard/review',
            name:'purchase_review',
            component:Review,
            children:[{
                path:'/dashboard/review/review_table',
                name:'review_table',
                component:Review_table
            },{
                path:'/dashboard/review/reviewer_table',
                name:'reviewer_table',
                component:Reviewer_table
            }]
        },
        {
            path:'/dashboard/parent_calculate',
            name:'parent_calculate',
            component:Parent_calculate
        },
        {
            path:'/dashboard/auto_project',
            name:'auto_project',
            component:Auto_project
        },
        {
            path:'/dashboard/auto_update',
            name:'auto_update',
            component:Auto_update
        },
        {
            path:'/dashboard/comment_m',
            name:'comment_m',
            component:Comment_m
        },
        {
            path:'/dashboard/monitor',
            name:'monitor',
            component:Monitor
        },
        {
            path:'/dashboard/main',
            name:'main',
            component:Main
        },
        {
            path:'/dashboard/change_l',
            name:'change_l',
            component:Change_l
        },
        {
            path:'/dashboard/sample',
            name:'sample',
            component:Sample
        },
        {
            path:'/dashboard/vendor',
            name:'vendor',
            component: ()=>import('./components/dashboard/vendor/Vendor.vue')
        },
        {
            path:'/dashboard/check',
            name:'check',
            component: ()=>import('./components/dashboard/purchase/check.vue')
        },
        {
            path:'/dashboard/application',
            name:'application',
            component: ()=>import('./components/dashboard/purchase/application.vue')
        },
        {
            path:'/dashboard/urgent_order',
            name:'urgent_order',
            component: ()=>import('./components/dashboard/purchase/urgent_order.vue')
        },
        {
            path:'/dashboard/timeout_order',
            name:'timeout_order',
            component: ()=>import('./components/dashboard/purchase/timeout_order.vue')
        },
        {
            path: '/dashboard/statistic',
            name: 'statistic',
            component: ()=>import('./components/dashboard/purchase/statistics.vue')
        },
        {
            path: '/dashboard/product',
            name: 'product',
            component: ()=>import('./components/dashboard/product.vue')
        },
        {
            path: '/dashboard/invoice',
            name: 'invoice',
            component: ()=>import('./components/dashboard/invoice.vue')
        },
        {
            path: '/dashboard/worker',
            name: 'worker',
            component: ()=>import('./components/dashboard/factory/worker.vue')
        },
        {
            path: '/dashboard/process',
            name: 'process',
            component: ()=>import('./components/dashboard/factory/process.vue')
        },
        {
            path: '/dashboard/record',
            name: 'record',
            component: ()=>import('./components/dashboard/factory/record.vue')
        },
    
    
    ],
    },
    {
        path: "*",
        redirect: '/login'
    }
];