<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">自动移入移出</el-menu-item>
            </el-menu>
        </div>
        <div class="select_menu">
            <!-- <el-input v-model="input" placeholder="搜索标题和id" style="width: 10%;"></el-input> -->
            <el-input v-model="query" placeholder="请输入父体名称" style="width: 200px; margin-left: 10px;"></el-input>
            <el-select v-model="filterText" placeholder="请选择店铺" style="margin-left: 10px;width:130px">
                <el-option v-for="item in filters" :key="item.value" :label="item.text" :value="item.value">
                </el-option>
            </el-select>
            <el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取本地父体</el-button>
            <el-button style="margin-left: 30px;" @click="download_asin" :loading="isSending">下载有货的异常asin表</el-button>
            <el-upload class="upload-demo" :http-request="httpRequest1" :on-preview="handlePreview" :file-list="fileList"
                :show-file-list="false">
                <el-button style="margin-left: 1560px;" size="small">批量上传<i class="el-icon-upload2"></i></el-button>
            </el-upload>
        </div>
        <div class="auto_update">
            <el-button style="margin-right: 10px;float:right" size="mini" @click="download_mo">下载批量上传模版<i
                    class="el-icon-download"></i></el-button>
            <div class="auto_update_div1">
                <div class="auto_update_table1">
                    <el-table :data="tableData" style="width: 100%" :show-header="false" height="100%" size>
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column prop="本地父体" label="本地父体">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="jojo(scope.row.本地父体)"
                                    @contextmenu.prevent="onRightClick(scope.row.本地父体)" v-if="scope.row.是否收藏 == '是'">
                                    *{{ scope.row.本地父体 }}
                                </div>
                                <div slot="reference" class="name-wrapper" @click="jojo(scope.row.本地父体)"
                                    @contextmenu.prevent="onRightClick(scope.row.本地父体)" v-if="scope.row.是否收藏 == '否'">
                                    {{ scope.row.本地父体 }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="auto_update_div1">
                <div class="auto_update_table2">
                    <el-table :data="tableData1" style="width: 100%" height="100%" border>
                        <el-table-column prop="本地父体" label="本地父体" width="168" fixed="left">
                            <template slot-scope="scope">
                                {{ scope.row.本地父体 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="本地品名" label="本地品名" width="168" fixed="left">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.本地品名 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Seller SKU" label="Seller SKU" width="268">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Seller_SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Standard Price" label="Standard Price" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Standard_Price }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Product ID" label="Product ID" width="160">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Product_ID }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Product ID Type" label="Product ID Type" width="160">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Product_ID_Type }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Parentage" label="Parentage" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Parentage }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Parent SKU" label="Parent SKU" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Parent_SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Relationship_Type" label="Relationship_Type" width="200">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Relationship_Type }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Variation Theme" label="Variation Theme" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Variation_Theme }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Update Delete" label="Update Delete" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Update_Delete }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Size Name" label="Size Name" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Size_Name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Color" label="Color" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Color }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Color Map" label="Color Map" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Color_Map }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Style" label="Style" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Style }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Title" label="Title" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.Title }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point1" label="bullet_point1" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point2" label="bullet_point2" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point2 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point3" label="bullet_point3" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point3 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point4" label="bullet_point4" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point4 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point5" label="bullet_point5" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point5 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bullet_point6" label="bullet_point6" width="500">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.bullet_point6 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="是否自动上传图片" label="是否获取当前sku上图" width="170">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.是否自动上传图片 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="SKU" label="指定上图SKU" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="150">
                            <template slot-scope="scope">
                                <el-button size="small" :loading="isSending4" plain style="float: right;margin-left: 3px;"
                                    @click="update(scope.row)">编辑/详情
                                </el-button>
                                <el-button size="small" :loading="isSending_in" style="float: right;" plain
                                    @click="update_click(scope.row)">点击移入移出
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>


        <el-dialog :title="title" :visible="dialogVisible1" width="35%" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="Seller SKU模版" prop="Seller SKU" label-width="20%">
                    <el-input v-model="form.Seller_SKU" placeholder="请输入msku模版" style="width: 50%;" />
                </el-form-item>
                <el-form-item label="Standard Price" prop="Standard Price" label-width="20%">
                    <el-input v-model="form.Standard_Price" placeholder="请输入价格" style="width: 50%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="Product ID" prop="Product ID">
                    <el-input v-model="form.Product_ID" style="width: 70%;" :disabled="true" />
                </el-form-item>
                <el-form-item label="Product ID Type" prop="Product ID Type" style="margin-left: -20px;"
                    label-width="120px">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.Product_ID_Type" placeholder="ID_Type" style="width: 100px;margin-left: 8%;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Parentage" prop="Parentage">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.Parentage" placeholder="请选择Parentage" style="width: 100px;margin-left: 24%;">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Parent SKU" prop="Parent SKU" style="margin-left: 12%;">
                    <el-input v-model="form.Parent_SKU" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="Relationship Type" prop="Relationship Type" label-width="125px">
                    <el-select v-model="form.Relationship_Type" placeholder="Type" style="width: 100px;">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Variation Theme" prop="Variation Theme" label-width="125px" style="margin-left: 50px;">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.Variation_Theme" placeholder="请选择" style="width: 190px;margin-left: -1%;;">
                        <el-option v-for="item in tip" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Update Delete" prop="Update Delete" label-width="125px">
                    <el-select v-model="form.Update_Delete" placeholder="Type" style="width: 135px;">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Size Name" prop="Size Name" style="margin-left: 17px;">
                    <el-input v-model="form.Size_Name" style="width: 80%;margin-left: 10%;" />
                </el-form-item>
                <el-form-item label="Color" prop="Color">
                    <el-input v-model="form.Color" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="Color Map" prop="Color Map">
                    <el-input v-model="form.Color_Map" style="width: 80%;margin-left: 2%;" />
                </el-form-item>
                <el-form-item label="Title" prop="Title">
                    <el-input v-model="form.Title" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point1" prop="bullet_point1">
                    <el-input v-model="form.bullet_point1" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point2" prop="bullet_point2">
                    <el-input v-model="form.bullet_point2" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point3" prop="bullet_point3">
                    <el-input v-model="form.bullet_point3" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point4" prop="bullet_point4">
                    <el-input v-model="form.bullet_point4" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point5" prop="bullet_point5">
                    <el-input v-model="form.bullet_point5" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="bullet_point6" prop="bullet_point6">
                    <el-input v-model="form.bullet_point6" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
                <el-form-item label="是否获取当前sku上图" prop="是否自动上传图片" label-width="125px">
                    <el-select v-model="form.是否自动上传图片" placeholder="Type" style="width: 135px;">
                        <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指定上图SKU" prop="SKU">
                    <el-input v-model="form.SKU" style="width: 80%;margin-left: 12%;" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="update_data" :loading="isSending2">确 定</el-button>
            </span>

        </el-dialog>


        <el-dialog :title="title" :visible="dialogVisible2" width="30%" :before-close="handleClose2">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item>
                    <el-button type="info" plain @click="download">下载附件</el-button>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="width: 195px;" class="upload-demo" ref="upload" :file-list="fileList"
                        :on-preview="handlePreview" action :http-request="httpRequest" :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success" :loading="isSending_register"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            query: '',  // 本地父体搜索条件
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            isSending: false,
            isSending1: false,
            isSending2: false,
            isSending4: false,
            isSending_in: false,
            isSending_register: false,
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            projectname: '',
            isButtonDisabled: false,
            fileData: [],
            form: {},
            size: '',
            title: '',
            visible: false,
            tableData: [],
            tableData1: [],
            // tableData1: [{ '项目名称': '三星S24 Ultra透明壳-美国', '项目内容': '三星S24 Ultra透明壳', '项目备注': '1', '执行人': '温智超', '维护动作': '备货发货', '是否周末执行': '否', '执行频率': 7 }],
            tableData2: [],
            tableDataover: [],
            selectedDate: '',
            num1: 1,
            rules: {
                项目名称: [{ required: true, message: '输入项目名称以新建任务', trigger: 'blur' }],
                项目内容: [{ required: true, message: '添加项目内容', trigger: 'blur' }],
                项目备注: [{ required: true, message: '添加项目备注', trigger: 'blur' }],
                执行人: [{ required: true, message: '请选择执行人', trigger: 'blur' }],
                维护动作: [{ required: true, message: '请选择维护动作', trigger: 'blur' }],
                项目负责人: [{ required: true, message: '请选择项目负责人', trigger: 'blur' }],
            },
            filters: [{ text: 'CoBak-US', value: 'CoBak-US' }, { text: 'CoBak-UK', value: 'CoBak-UK' }, { text: 'CoBak-SG', value: 'CoBak-SG' }, { text: 'CoBak-SE', value: 'CoBak-SE' }, { text: 'CoBak-PL', value: 'CoBak-PL' }, { text: 'CoBak-NL', value: 'CoBak-NL' }, { text: 'CoBak-MX', value: 'CoBak-MX' }, { text: 'CoBak-JP', value: 'CoBak-JP' }, { text: 'CoBak-IT', value: 'CoBak-IT' }, { text: 'CoBak-INDIA-IN', value: 'CoBak-INDIA-IN' }, { text: 'CoBak-FR', value: 'CoBak-FR' }, { text: 'CoBak-ES', value: 'CoBak-ES' }, { text: 'CoBak-DE', value: 'CoBak-DE' }, { text: 'CoBak-CA', value: 'CoBak-CA' }, { text: 'CoBak-BR', value: 'CoBak-BR' }, { text: 'CoBak Direct-BE', value: 'CoBak Direct-BE' }, { text: 'SMIFACE-CA', value: 'SMIFACE-CA' }, { text: 'SMIFACE-US', value: 'SMIFACE-US' }, { text: 'IDLEHANDS-US-CA', value: 'IDLEHANDS-US-CA' }, { text: 'iDLEHANDS-US-MX', value: 'iDLEHANDS-US-MX' }, { text: 'iDLEHANDS-US-US', value: 'iDLEHANDS-US-US' }],
            filterText: '',
            tip: [{
                value: 'Size',
                label: 'Size'
            }, {
                value: 'Color',
                label: 'Color'
            }, {
                value: 'color-size',
                label: 'color-size'
            }, {
                value: 'SizeNmae',
                label: 'SizeNmae'
            }, {
                value: 'SizeName-ColorName',
                label: 'SizeName-ColorName'
            }, {
                value: 'ColorName',
                label: 'ColorName'
            }],
            options: [{
                value: 'GTAN',
                label: 'GTAN'
            }, {
                value: 'EAN',
                label: 'EAN'
            }, {
                value: 'UPC',
                label: 'UPC'
            }, {
                value: 'ASIN',
                label: 'ASIN'
            }],
            options1: [{
                value: 'Prant',
                label: 'Prant'
            }, {
                value: 'Chlid',
                label: 'Child'
            }],
            options2: [{
                value: 'Accessory',
                label: 'Accessory'
            }, {
                value: 'Variation',
                label: 'Variation'
            }, {
                value: 'package_contains',
                label: 'package_contains'
            }],
            options3: [{
                value: 'Update',
                label: 'Update'
            }, {
                value: 'PartialUpdate',
                label: 'PartialUpdate'
            }, {
                value: 'Delete',
                label: 'Delete'
            }],
            options4: [{
                value: '是',
                label: '是'
            }, {
                value: '否',
                label: '否'
            }],
            value: '',
            visible1: false,
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            named: '',
            fand: 0,
            store: [],
            flagnum: 0,
        }
    },
    methods: {
        onRightClick(asin) {
            // 这里编写右键点击时的处理逻辑
            // 可以在这里打开自定义的菜单组件
            this.title = asin + '(模版上传-下载)'
            this.named = asin
            this.dialogVisible2 = true
        },
        // checkStatus(row) {
        //     // 根据执行状态判断是否禁用按钮
        //     if (row.执行状态 === '已完成') {
        //         return true; // 禁用按钮
        //     } else {
        //         return false; // 启用按钮
        //     }
        // },
        init() {
            this.isSending = true
            let params = {
                query: this.query
            } 
            this.$axios.get('/vue/auto_update/seller_asin_select', { params }).then(res => {
                console.log(res);
                if (res.msg == 'success') {
                    this.tableData = res.data
                    this.isSending = false
                } else {
                    alert(res.message)
                    this.isSending = false
                }
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        update(row) {
            this.title = row.本地品名
            this.form = {}
            this.form = row
            this.dialogVisible1 = true
        },
        handlePreview(file) {
            console.log(file);
        },
        handleClose() {
            this.dialogVisible = false
            this.init()
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.fand = 0
            this.jojo(this.form.本地父体)
        },
        handleClose2() {
            this.dialogVisible2 = false
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        jojo(project) {
            if (this.filterText == '') {
                alert('请选择店铺')
            } else {
                this.$axios.post('/vue/auto_update/select_child', { name: project, shop: this.filterText }).then(res => {
                    console.log(res);
                    if (res.msg == 'success') {
                        this.tableData1 = res.data
                    } else {
                        alert('未获取到该父体的品名数据')
                    }
                })
                    .console(error => {
                        console.log(error)
                        alert('发生错误:==' + error)
                    })
            }
        },

        update_click(row) {
            this.isSending_in = true
            setTimeout(() => {
                alert("该点击指令已生效，可以稍后前往上传记录查看（大概有个十几秒延迟）")
                this.isSending_in = false
            }, 10000);
            this.$axios.post('/vue/auto_update/update_click', row).then(res => {
                console.log(res);
                if (res.msg == '正在进行中') {
                    alert("该品名移入移出正在进行中")
                    this.isSending_in = false
                } else {
                    alert("该品名移入移出已在进行中,请勿重复操作")
                    this.isSending_in = false
                }
            })
                .console(error => {
                    console.log(error)
                    this.isSending_in == false
                })
        },
        pro_action_er(pname, er, action) {
            this.flagnum = 2
            this.$axios.post('/vue/auto_update/actioner_select', { name: pname, er: er, action: action }).then(res => {
                console.log(res);
                this.tableData2 = res.data
                this.isSending4 = false
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        jojo1(project, er, action) {
            this.$axios.post('/vue/auto_update/action_day', { name: project }).then(res => {
                console.log(res);
                this.tableData1 = res.data
                this.pro_action_er(project, er, action)
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        update_data() {
            this.isSending2 = true
            console.log(this.form);
            this.$axios.post('/vue/auto_update/update_data', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                    this.isSending2 = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending2 = false
                }
            })
                .catch(error => {
                    console.log(error)
                    alert('发生错误:==' + '请检查内容是否填写完整')
                    this.isSending2 = false
                })
        },
        register(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    console.log("有效")
                    this.$refs.upload.submit();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        httpRequest(param) {
            this.isSending_register = true
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("name", this.title);
            this.$axios.post('/vue/auto_update/auto_update_upload', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
                this.isSending_register = false
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:==' + error)
            });
        },
        httpRequest1(param) {
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("name", this.title);
            this.$axios.post('/vue/auto_update/auto_update_upload_in', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
                this.isSending_register = false
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:==' + error)
            });
        },
        download_mo() {
            let moname = '编辑品名移入移出模版'
            let url = "https://kebeidianzi.com/vue/auto_update/auto_update_download_mo/" + moname + '.xlsx'
            this.$axios.get(`/vue/auto_update/auto_update_download_mo/` + moname + '.xlsx').catch(error => {
                console.log("错误", error)
            })
            window.open(url, '_blank')
        },
        download_asin() {
            let moname = '有货的异常asin'
            let url = "https://kebeidianzi.com/vue/auto_update/auto_update_download_asin/" + moname + '.xlsx'
            this.$axios.get(`/vue/auto_update/auto_update_download_asin/` + moname + '.xlsx').catch(error => {
                console.log("错误", error)
            })
            window.open(url, '_blank')
        },
        download() {
            let url = "https://kebeidianzi.com/vue/auto_update/auto_update_download/" + this.named + '(模版上传-下载).xlsx'
            this.$axios.get(`/vue/auto_update/auto_update_download/` + this.named + '(模版上传-下载).xlsx').catch(error => {
                console.log("错误", error)
            })
            window.open(url, '_blank')
        },
        auto_update_filedata(name, er, action) {
            this.$axios.post(`/vue/auto_update/auto_update_filedata`, { name: name, er: er, action: action }).then(res => {
                console.log(res)
                this.fileData = res.data
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.auto_update {
    /* border: 1px solid red; */
    height: 88.9%;
}

.auto_update_div1 {
    width: 14.2%;
    height: 94%;
    margin: 20px;
    float: left;


}

.auto_update_div3 {
    width: 23%;
    height: 94%;
    margin: 20px;
    float: left;

}

.auto_update_table1 {
    width: 100%;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 10px;


}

.auto_update_table2 {
    width: 580%;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 10px;

}

.auto_update_table3 {
    width: 800px;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;
    margin-left: 48%;

}

.green-color {
    color: green;
}

.yellow-color {
    color: rgb(192, 192, 84);
}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>