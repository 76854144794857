<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">评论管理系统</el-menu-item>
            </el-menu>
        </div>
        <div class="comment_select_menu">
            <el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取产品表现</el-button>
            <el-button style="margin-left: 20px;" @click="update_data" :loading="isSending2">更新产品表现数据</el-button>
            <el-button style="margin-left: 20px;" @click="table_review">评论占用审批</el-button>
            <el-button style="margin-left: 20px;" @click="download" :loading="isSending3">下载表格</el-button>
            <el-select v-model="filterText" placeholder="全部店铺" style="margin-left: 26.1%;">
                <el-option v-for="item in filters" :key="item.value" :label="item.text" :value="item.value">
                </el-option>
            </el-select>
            <el-button style="margin-left: 5px;" @click="select_s" :loading="isSending9">筛选</el-button>

            <el-select v-model="select_type" placeholder="请选择类型" style="margin-left: 30px;width: 7%;">
                <el-option v-for="item in select" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="select_input"
                style="margin-left: 10px;width: 13%;">
            </el-input>
            <el-button style="margin-left: 5px;" @click="select_m" :loading="isSending8">搜索</el-button>
        </div>
        <div class="comment">
            <div class="comment_div1">
                <el-select v-model="sortField" placeholder="排序方" style="margin-left: 57.4%; width: 100px;" size="small">
                    <el-option label="评分" value="评分"></el-option>
                    <el-option label="评论数" value="评论数"></el-option>
                    <el-option label="FBA可售" value="FBA可售"></el-option>
                </el-select>
                <el-select v-model="sortOrder" placeholder="排序" style="margin-left: 10px;width: 96px;" size="small">
                    <el-option label="升序" value="升序"></el-option>
                    <el-option label="降序" value="降序"></el-option>
                </el-select>
                <el-button style="margin-left: 5px;" @click="select_p" :loading="isSending10" size="small"
                    icon="el-icon-search"></el-button>
                <!-- <div style="width: 100%;"><span>未完成</span></div> -->
                <!-- <el-button size="medium" @click="add" style="width: 100%;">新增项目</el-button> -->
                <div class="comment_table1">

                    <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
                        style="width: 100%" height="100%" stripe>
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column prop="ASIN" label="ASIN" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    <a :href="buildAmazonLink(scope.row.ASIN)" target="_blank">{{ scope.row.ASIN }}</a>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="父ASIN" label="父ASIN" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.父ASIN }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="MSKU" label="MSKU" width="268">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.MSKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="店铺" label="店铺" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.店铺 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="品名" label="品名/SKU" width="298">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.品名 }}
                                </div>
                                {{ scope.row.sku }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="评分" label="评分" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.评分 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="评论数" label="评论数" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.评论数 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="FBA可售" label="FBA可售" width="200">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.FBA可售 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="是否有图" label="是否有图" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.是否有图 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="是否有字" label="是否有字" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.是否有字 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="大类" label="大类" width="200">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.大类 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="110">
                            <template slot-scope="scope">
                                <el-button size="small" :loading="isSending4" plain
                                    @click="handleButtonClick(scope.row)">编辑/详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
                    </el-pagination>
                </div>
            </div>

            <!-- <div class="review_div1">
                <div class="review_table2">
                    <router-view :key="$route.fullPath">

                    </router-view>
                </div>
            </div> -->
        </div>
        <el-dialog :title="title" :visible="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="是否有字" prop="是否有字">
                    <el-select v-model="form.是否有字" placeholder="请选择是否有字">
                        <el-option v-for="item in youzi" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.占用状态 == '未占用' ? 'info' : (form.占用状态 == '已被占用' ? 'success' : form.占用状态 == '申请中' ? 'primary' : (form.占用状态 == '' ? 'warning' : 'danger')))">
                        {{ form.占用状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="是否有图" prop="是否有图">
                    <el-select v-model="form.是否有图" placeholder="请选择是否有图">
                        <el-option v-for="item in youtu" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="大类" prop="大类">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.大类" autocomplete="off" style="width: 47%;" placeholder="请填写大类"></el-input>
                </el-form-item>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                    :inline="true">
                    <el-form-item label="申请人" prop="申请人">
                        <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                        <el-select v-model="form.申请人" placeholder="请选择申请人">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain style="margin-left: 54%;" @click="comment_u" size="small"
                            :loading="isSending_u">申请占用</el-button>
                    </el-form-item>
                </el-form>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px"
                    label-position="left"></el-form>
                <!-- <el-form-item label="是否被占用" prop="是否被占用">
                    <el-select v-model="form.QA" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save" :loading="isSending">提交</el-button>
            </span>
        </el-dialog>

        <el-drawer title="评论占用审批" :visible.sync="table" direction="rtl" size="50%">
            <el-table :data="tableuse">
                <el-table-column prop="ASIN" label="ASIN" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.asin }}
                                </div>
                            </template>
                </el-table-column>
                <el-table-column prop="店铺" label="店铺" width="108">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.店铺 }}
                                </div>
                            </template>
                </el-table-column>
                <el-table-column prop="申请人" label="申请人" width="108">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.申请人 }}
                                </div>
                            </template>
                </el-table-column>
                <el-table-column prop="申请时间" label="申请时间" width="238">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.创建时间 }}
                                </div>
                            </template>
                </el-table-column>
                <el-table-column label="功能" width="160">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <el-popconfirm title="确认审批不通过吗？" @confirm="comment_danger(scope.row)">
                                        <el-button  size="mini" type="danger" plain :loading="isSending_danger"
                                            slot="reference" style="float: right;">不通过</el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认审批通过吗？" @confirm="comment_success(scope.row)">
                                        <el-button  size="mini" type="success" plain :loading="isSending_success"
                                            slot="reference" style="float: right; margin-right: 15px;">通过</el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
            </el-table>
        </el-drawer>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            table: false,
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            select_input: '',
            select_type: '',
            isSending: false,
            isSending2: false,
            isSending3: false,
            isSending8: false,
            isSending9: false,
            isSending10: false,
            isSending_u: false,
            isSending_danger: false,
            isSending_success: false,
            dialogVisible: false,
            form: {},
            size: '',
            title: '',
            visible: false,
            tableuse:[],
            tableData: [],
            tableDataover: [],
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '吴尚熹',
                label: '吴尚熹'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }],
            select: [{
                value: 'ASIN',
                label: 'ASIN'
            }, {
                value: 'MSKU',
                label: 'MSKU'
            }, {
                value: 'sku',
                label: 'sku'
            }],
            youzi: [{
                value: '是',
                label: '是'
            }, {
                value: '否',
                label: '否'
            }],
            youtu: [{
                value: '有图',
                label: '有图'
            }, {
                value: '无图',
                label: '无图'
            }],
            zhanyon: [{
                value: '已被占用',
                label: '已被占用'
            }, {
                value: '未被占用',
                label: '未被占用'
            }],
            filters: [{ text: 'CoBak-US', value: 'CoBak-US' }, { text: 'CoBak-UK', value: 'CoBak-UK' }, { text: 'CoBak-SG', value: 'CoBak-SG' }, { text: 'CoBak-SE', value: 'CoBak-SE' }, { text: 'CoBak-PL', value: 'CoBak-PL' }, { text: 'CoBak-NL', value: 'CoBak-NL' }, { text: 'CoBak-MX', value: 'CoBak-MX' }, { text: 'CoBak-JP', value: 'CoBak-JP' }, { text: 'CoBak-IT', value: 'CoBak-IT' }, { text: 'CoBak-INDIA-IN', value: 'CoBak-INDIA-IN' }, { text: 'CoBak-FR', value: 'CoBak-FR' }, { text: 'CoBak-ES', value: 'CoBak-ES' }, { text: 'CoBak-DE', value: 'CoBak-DE' }, { text: 'CoBak-CA', value: 'CoBak-CA' }, { text: 'CoBak-BR', value: 'CoBak-BR' }, { text: 'CoBak Direct-BE', value: 'CoBak Direct-BE' }, { text: 'SMIFACE-CA', value: 'SMIFACE-CA' }, { text: 'SMIFACE-US', value: 'SMIFACE-US' }, { text: 'IDLEHANDS-US-CA', value: 'IDLEHANDS-US-CA' }, { text: 'iDLEHANDS-US-MX', value: 'iDLEHANDS-US-MX' }, { text: 'iDLEHANDS-US-US', value: 'iDLEHANDS-US-US' }],
            value: '',
            filterText: '',
            value1: [],
            value2: [],
            value3: [],
            sortField: '',
            sortOrder: '',
            asin_h1: '是',
            asin_h2: '否',
            formLabelWidth: '80px',
            timer: null,
            sxsx: '',
            shop:{},
            currentPage: 1,
            pageSize: 10,
            select_n: 0
        }
    },
    methods: {
        // @size-change页码展示数量点击事件
        handleSizeChange(val) {
            // 更新每页展示数据size
            this.pageSize = val
        },
        // @current-change页码点击事件
        handleCurrentChange(val) {
            // 更新当前页数是第几页
            this.currentPage = val
        },
        init() {
            this.select_n = 0
            this.isSending = true
            this.$axios.get('/vue/comment/comment_all').then(res => {
                console.log(res);
                this.tableData = res.data
                this.isSending = false
            })
                .console(error => {
                    console.log(error)
                    this.isSending = false
                })
        },
        buildAmazonLink(asin) {
            return `https://www.amazon.com/dp/${asin}`;
        },
        update_data() {
            this.isSending2 = true
            this.$axios.get('/vue/comment/comment_all_updata').then(res => {
                if (res.code == 0) {
                    console.log(res);
                    alert('更新中，预计时间为80秒')
                    this.isSending2 = false
                } else if (res.code == 1) {
                    alert('正在更新中,请耐心等待')
                    this.isSending2 = false
                }
            }).console(error => {
                console.log(error)
                this.isSending2 = false
            })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        add() {
            this.dialogVisible = true
            this.title = '创建项目'
            this.form = {}
        },
        table_review(){
            this.table = true
            this.comment_review()
        },
        handleButtonClick(row) {
            this.form = {}
            this.shop = {}
            this.title = row.ASIN
            this.form.asin = row.ASIN
            this.shop.asin = row.ASIN
            this.form.父ASIN = row.父ASIN
            this.form.msku = row.MSKU
            this.shop.msku = row.MSKU
            this.form.sku = row.sku
            this.shop.sku = row.sku
            this.form.店铺 = row.店铺
            this.shop.店铺 = row.店铺
            this.dialogVisible = true
            this.comment_data_select(row.ASIN, row.店铺)

        },
        comment_review(){
            this.$axios.get('/vue/comment/comment_review').then(res => {
                console.log(res);
                this.tableuse = res.data
            })
                .console(error => {
                    console.log(error)
                })
        },
        comment_u() {
            this.isSending_u = true
            if (this.form.占用状态 == '未占用') {
                if (this.form.asin == this.form.父ASIN) {
                    alert('已合并父体，无法申请')
                    this.isSending_u = false
                }else if (this.form.asin == '111111'){
                    alert('请先提交填写有无图片，有无有字，大类')
                    this.isSending_u = false
                }else {
                    this.comment_u_insert()
                }
            } else if (this.form.占用状态 == '申请中') {
                alert('已向主管申请审批，请勿重复操作')
                this.isSending_u = false
            } else if (this.form.占用状态 == '已占用') {
                alert('该asin评论管理已被占用，请勿重复操作')
                this.isSending_u = false
            }else {
                alert('未知报错，请联系IT')
            }
        },
        comment_u_insert() {
            this.$axios.post('/vue/comment/comment_u_insert', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                    this.isSending_u = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending_u = false
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查是否已选择申请人')
                this.isSending_u = false
            })
        },
        comment_data_select(asin, shop) {
            this.$axios.post(`/vue/comment/comment_data_select`, { asin: asin, shop: shop }).then(res => {
                console.log(res)
                this.form = res.data
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        handleClose() {
            this.dialogVisible = false
            if (this.select_n == 0) {
                this.init()
            } else if (this.select_n == 1) {
                this.select_m()
            } else if (this.select_n == 2) {
                this.select_s()
            }
            else if (this.select_n == 3) {
                this.select_p()
            }
        },
        handleClose1() {
            this.comment_review()
        },
        comment_danger(row){
            this.isSending_danger = true
            this.$axios.post('/vue/comment/comment_danger', row).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending_danger = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending_danger = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查内容是否填写完整')
                this.isSending = false
            })
        },
        comment_success(row){
            this.isSending_success = true
            this.$axios.post('/vue/comment/comment_success', row).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending_success = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending_success = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==')
                this.isSending_success = false
            })
        },
        save() {
            this.isSending = true
            this.form.店铺 = this.shop.店铺
            this.form.asin = this.shop.asin
            this.form.sku = this.shop.sku
            this.form.msku = this.shop.msku
            console.log(this.form);
            this.$axios.post('/vue/comment/comment_t', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查内容是否填写完整')
                this.isSending = false
            })
        }
        ,
        rightclick() {
            this.visible = true
        },
        select_m() {
            this.select_n = 1
            this.isSending8 = true
            this.$axios.post(`/vue/comment/comment_select`, { type: this.select_type, input: this.select_input }).then(res => {
                console.log(res)
                this.tableData = res.data
                this.isSending8 = false
            }).catch(error => {
                alert(error)
                console.log(error)
                this.isSending8 = false
            })
        },
        select_s() {
            this.select_n = 2
            this.isSending9 = true
            this.$axios.post(`/vue/comment/comment_select_s`, { form: this.tableData, input: this.filterText }).then(res => {
                console.log(res)
                this.tableData = res.data
                this.isSending9 = false
            }).catch(error => {
                alert(error)
                console.log(error)
                this.isSending9 = false
            })
        },
        select_p() {
            this.select_n = 3
            this.isSending10 = true
            this.$axios.post(`/vue/comment/comment_select_p`, { form: this.tableData, field: this.sortField, order: this.sortOrder }).then(res => {
                console.log(res)
                this.tableData = res.data
                this.isSending10 = false
            }).catch(error => {
                alert(error)
                console.log(error)
                this.isSending10 = false
            })
        },
        download() {
            this.isSending3 = true
            this.$axios.post(`/vue/comment/comment_download`, this.tableData).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.download1()
                }
            }).catch(error => {
                alert(error)
                console.log(error)
                this.isSending3 = false
            })
        },
        download1() {
            let url = "https://kebeidianzi.com/vue/comment/comment_download1/产品表现_评论管理.xlsx"
            this.$axios.get('/vue/comment/comment_download1/产品表现_评论管理.xlsx').catch(error => {
                console.log("错误", error)
                this.isSending3 = false
            })
            this.isSending3 = false
            window.open(url, '_blank')
        },

    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.comment_select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.comment {
    /* border: 1px solid red; */
    height: 88.9%;
}

.comment_div1 {
    width: 98%;
    height: 98%;
    margin: 20px;
    margin-top: 0px;
    float: left;

}

.comment_table1 {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    height: 92%;

}

.comment_table2 {
    width: 1500px;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;

}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>