<template>
  <div class="background-template h-100" :style="getBackgroundCustomization">
    <!-- Navbar -->
    <Navbar :user_email="user_email" />
  </div>

  
</template>
<script>
import Navbar from "./Navbar";
// import VueContentLoading from "vue-content-loading"; // TODO: implement this
export default {
  components: {
    Navbar,
    
  }
}
 
</script>
<style>


.background-template {
  /* background-image: url("~@/assets/images/background.jpg");
  background-size: cover !important; */
  background-color: rgb(247, 245, 245);
  
}
</style>