<template>
  <div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
      background-color="rgb(250, 250, 250)">
      <el-submenu index="1">
        <template slot="title">FBA出货</template>
        <el-menu-item index="1-1">物料装箱</el-menu-item>
        <el-menu-item index="1-2">物料信息查询</el-menu-item>
        <el-menu-item index="1-3">物料出库</el-menu-item>
        <el-menu-item index="1-4">物料入库</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">测试1</template>
        <el-menu-item index="2-1">选项1</el-menu-item>
        <el-menu-item index="2-2">选项2</el-menu-item>
        <el-menu-item index="2-3">选项3</el-menu-item>
        <el-submenu index="2-4">
          <template slot="title">测试2</template>
          <el-menu-item index="2-4-1">选项1</el-menu-item>
          <el-menu-item index="2-4-2">选项2</el-menu-item>
          <el-menu-item index="2-4-3">选项3</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-menu-item index="3" disabled>消息中心</el-menu-item>
      <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">出货记录</a></el-menu-item>
    </el-menu>

    </div>
</template>
  
<script>
export default {
  data() {
    return {

    };
  },
  //   methods: {
  //     handleOpen(key, keyPath) {
  //       console.log(key, keyPath);
  //     },
  //     handleClose(key, keyPath) {
  //       console.log(key, keyPath);
  //     }
  //   }
}
</script>
  
<style></style>