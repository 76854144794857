<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
<style>
@import url('./assets/fonts/index.scss');

html,
body,
#app {
  height: 100%;
}


* {
  font-family: 'harmony';
}
</style>
