<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">样品监控系统</el-menu-item>
            </el-menu>
        </div>
        <div class="sample_select_menu">
            <el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取样品监控列表</el-button>
            <el-button style="margin-left: 20px;" @click="insert">添加新样品监控</el-button>
            <el-input placeholder="请输入SKU" prefix-icon="el-icon-search" v-model="select_input"
                style="margin-left: 33%;width: 13%;">
            </el-input>
            <el-button style="margin-left: 5px;" @click="select_m" :loading="isSending8">搜索</el-button>
        </div>
        <div class="sample">
            <div class="sample_div1">
                <div class="sample_table1">
                    <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
                        style="width: 70%" height="100%">
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column prop="SKU" label="SKU" width="268">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="创建人" label="创建人" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.创建人 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="状态" label="状态" width="258">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.状态 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="创建时间" label="创建时间" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.创建时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="修改时间" label="修改时间" width="168">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    style="padding-top: 5px;">
                                    {{ scope.row.修改时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150">
                            <template slot-scope="scope">
                                <el-popconfirm title="确认删除吗？"
                                    @confirm="delete_sample(scope.row)">
                                    <el-button size="small" plain type="danger" slot="reference">删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="title" :visible="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="SKU" prop="SKU">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.SKU" autocomplete="off" style="width: 47%;" placeholder="请填写SKU"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" :loading="isSending_i">提交</el-button>
            </span>
        </el-dialog>
        
        <el-dialog :title="title" :visible="dialogVisible1" width="30%" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="MSKU" prop="MSKU">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.MSKU" autocomplete="off" style="width: 47%;" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="店铺" prop="店铺">
                        <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.店铺" autocomplete="off" style="width: 47%;" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="最低价格" prop="最低价格">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.最低价格" autocomplete="off" style="width: 47%;" placeholder="请填写最低价格"></el-input>
                </el-form-item>
                <el-form-item label="最高价格" prop="最高价格">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.最高价格" autocomplete="off" style="width: 47%;" placeholder="请填写最高价格"></el-input>
                </el-form-item>
                <el-form-item label="评论下限" prop="评论下限">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.评论下限" autocomplete="off" style="width: 47%;" placeholder="请填写评论下限"></el-input>
                </el-form-item>
                <el-form-item label="评论上限" prop="评论上限">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.评论上限" autocomplete="off" style="width: 47%;" placeholder="请填写评论上限"></el-input>
                </el-form-item>
                <!-- <el-form-item label="是否被占用" prop="是否被占用">
                    <el-select v-model="form.QA" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="update" :loading="isSending_u">修改</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            input: '',
            table: false,
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            select_input: '',
            select_type: '',
            isSending: false,
            isSending2: false,
            isSending3: false,
            isSending8: false,
            isSending9: false,
            isSending10: false,
            isSending_i: false,
            isSending_danger: false,
            isSending_u: false,
            dialogVisible: false,
            form: {},
            size: '',
            title: '',
            visible: false,
            tableuse:[],
            tableData: [],
            tableDataover: [],
            options: [{ value: 'CoBak-US', label: 'CoBak-US' }, { value: 'CoBak-UK', label: 'CoBak-UK' }, { value: 'CoBak-SG', label: 'CoBak-SG' }, { value: 'CoBak-SE', label: 'CoBak-SE' }, { value: 'CoBak-PL', label: 'CoBak-PL' }, { value: 'CoBak-NL', label: 'CoBak-NL' }, { value: 'CoBak-MX', label: 'CoBak-MX' }, { value: 'CoBak-JP', label: 'CoBak-JP' }, { value: 'CoBak-IT', label: 'CoBak-IT' }, { value: 'CoBak-INDIA-IN', label: 'CoBak-INDIA-IN' }, { value: 'CoBak-FR', label: 'CoBak-FR' }, { value: 'CoBak-ES', label: 'CoBak-ES' }, { value: 'CoBak-DE', label: 'CoBak-DE' }, { value: 'CoBak-CA', label: 'CoBak-CA' }, { value: 'CoBak-BR', label: 'CoBak-BR' }, { value: 'CoBak Direct-BE', label: 'CoBak Direct-BE' }, { value: 'SMIFACE-CA', label: 'SMIFACE-CA' }, { value: 'SMIFACE-US', label: 'SMIFACE-US' }, { value: 'IDLEHANDS-US-CA', label: 'IDLEHANDS-US-CA' }, { value: 'iDLEHANDS-US-MX', label: 'iDLEHANDS-US-MX' }, { value: 'iDLEHANDS-US-US', label: 'iDLEHANDS-US-US' }],
            value1: [],
            value2: [],
            value3: [],
            sortField: '',
            sortOrder: '',
            formLabelWidth: '80px',
            timer: null,
            sxsx: '',
            shop:{},
            currentPage: 1,
            pageSize: 20,
            select_n: 0
        }
    },
    methods: {
        // @size-change页码展示数量点击事件
        handleSizeChange(val) {
            // 更新每页展示数据size
            this.pageSize = val
        },
        // @current-change页码点击事件
        handleCurrentChange(val) {
            // 更新当前页数是第几页
            this.currentPage = val
        },
        init() {
            this.isSending = true
            this.$axios.get('/vue/sample/sample_all').then(res => {
                console.log(res);
                this.tableData = res.data
                this.isSending = false
            })
                .console(error => {
                    console.log(error)
                    this.isSending = false
                })
        },
        select_m(){
            this.isSending8 = true
            this.$axios.post('/vue/sample/select_sample', {input:this.select_input}).then(res => {
                console.log(res);
                this.tableData = res.data
                this.currentPage = 1;
                this.isSending8 = false
            })
                .console(error => {
                    console.log(error)
                    this.isSending8 = false
                })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        add() {
            this.dialogVisible = true
            this.title = '创建项目'
            this.form = {}
        },
        insert(){
            this.form = {}
            this.title = '新增监控'
            this.dialogVisible = true
        },
        handleButtonClick(row) {
            this.form = {}
            this.title = '编辑监控'
            this.form = row
            this.form.MSKU = row.MSKU
            this.form.店铺 = row.店铺
            this.form.最低价格 = row.最低价格
            this.form.最高价格 = row.最高价格
            this.form.评论上限 = row.评论上限
            this.form.评论下限 = row.评论下限
            this.dialogVisible1 = true
        },
        sample_review(){
            this.$axios.get('/vue/sample/sample_review').then(res => {
                console.log(res);
                this.tableuse = res.data

            })
                .console(error => {
                    console.log(error)
                })
        },
        handleClose() {
            this.dialogVisible = false
            this.init()
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.init()
        },
        sample_danger(row){
            this.isSending_danger = true
            this.$axios.post('/vue/sample/sample_danger', row).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending_danger = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending_danger = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查内容是否填写完整')
                this.isSending = false
            })
        },
        delete_sample(row){
            this.isSending = true
            this.$axios.post(`/vue/sample/delete_sample`, row).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                    this.isSending = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending = false
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误' + error)
                this.isSending = false
            })
        },
        sample_success(row){
            this.isSending_success = true
            this.$axios.post('/vue/sample/sample_success', row).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending_success = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending_success = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==')
                this.isSending_success = false
            })
        },
        save() {
            this.isSending_i = true
            console.log(this.form);
            this.$axios.post(`/vue/sample/sample_data_insert`, this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                    this.isSending_i = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending_i = false
                }
            }).catch(error => {
                console.log(error)
                alert('请检查是否填写完整' + error)
                this.isSending_i = false
            })
        },
        update() {
            this.isSending_u = true
            console.log(this.form);
            this.$axios.post(`/vue/sample/sample_data_update`, this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                    this.isSending_u = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending_u = false
                }
            }).catch(error => {
                console.log(error)
                alert('请检查是否填写完整' + error)
                this.isSending_u = false
            })
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.sample_select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.sample {
    /* border: 1px solid red; */
    height: 88.9%;
}

.sample_div1 {
    width: 96%;
    height: 98%;
    margin: 10px;
    margin-top: 0px;
    float: left;

}

.sample_table1 {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    height: 92%;

}

.sample_table2 {
    width: 1500px;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;

}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>